export default [
  {
    label: 'Name',
    field: 'name',
  },
  {
    label: 'Email',
    field: 'email',
  },
  {
    label: 'Roles',
    field: 'roles',
  },
  {
    label: 'Active',
    field: 'isActive',
  },
  {
    label: 'Action',
    field: 'action',
  },
]
